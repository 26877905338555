function noop() {
  console.log('------TRACK------'); // eslint-disable-line
  console.log([].slice.call(arguments).join(', ')); // eslint-disable-line
  console.log('------------------'); // eslint-disable-line
}

/**
 * Helper function for tracking.
 *
 * @param {string} action
 * @param {string} endpoint
 * @param {string} data
 *
 * @return {void}
 */
const track = (action, data, endpoint) => {
  const ga = window.ga || noop;
  const mixpanel = window.mixpanel || noop;

  switch (action) {
    case 'page':
      ga('send', 'pageview', data);
      mixpanel.track('page viewed', { url: data });
      break;

    case 'event':
      ga('send', 'event', data.category, data.action, data.label);
      mixpanel.track(data.category, {
        action: data.action,
        label: data.label,
      });
      break;

    case 'apiError':
      ga('send', 'event', 'error', endpoint, data);
      mixpanel.track('API Error', {
        endpoint: endpoint,
        data: data,
      });
      break;

    default:
    // no-op
  }
};

export default track;
