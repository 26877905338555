import React, { useEffect, useState } from 'react';

import TalentsFilter from './TalentsFilter';
import MobileFilter from './MobileFilter/MobileFilter';
import DesktopFilter from './DesktopFilter/DesktopFilter';

// _variables.scss md breakpoint
const BREAK_POINT = 992;

const index = props => {
  const [mobile, setMobile] = useState(window.innerWidth <= BREAK_POINT);

  const handleResize = event => {
    if (event.target.innerWidth <= BREAK_POINT) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <TalentsFilter {...props}>
      {rest => {
        if (mobile) {
          return <MobileFilter {...rest} />;
        }

        return <DesktopFilter {...rest} />;
      }}
    </TalentsFilter>
  );
};

export default index;
