import React, { PureComponent } from 'react';
import { bool, number, string } from 'prop-types';
import styles from './counter.scss';
import classNames from 'clsx';

/**
 * This is the Counter component.
 *
 * @author Pelle Lundgren <pelle.lundgren@vntrs.com>
 */
export class Counter extends PureComponent {
  /**
   * Declare expected prop types.
   *
   * @type {object}
   */
  static propTypes = {
    light: bool,
    dark: bool,
    accent: bool,
    count: number.isRequired,
    className: string,
  };

  /**
   * Render Counter.
   *
   * @return {Object}
   */
  render() {
    const {
      light,
      dark,
      accent,
      count,
      className: customClassName,
    } = this.props;

    return (
      <span
        className={classNames(styles.counter, customClassName, {
          [styles.light]: light,
          [styles.dark]: dark,
          [styles.accent]: accent,
        })}
      >
        {count}
      </span>
    );
  }
}

export default Counter;
