function custom_post(url, data, ok_callback) {
  const token = data['token'];

  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json();
    })
    .then(result => {
      console.log(
        'Call to ' +
          url +
          ' with data ' +
          JSON.stringify(data, null, 4) +
          'returns: ' +
          JSON.stringify(result, null, 4)
      );

      if ('error' in result) {
        alert('An error occured: ' + result['user_message']);
      } else {
        ok_callback(result);
      }
    })
    .catch(error => {
      console.error(error);
      alert(error);
    });
}

// Custom method for sending an sms invite to a prefilled user
window.invite_prefilled_user = function(base_url, user_id, token, input_id) {
  const phone_number = document.getElementById(input_id).value;
  const url = base_url + '/v1/admin/user/prefill/invite';
  const data = {
    user_id: user_id,
    token: token,
    phone_number: phone_number,
  };

  console.log('Sending SMS to ' + user_id + ' at number ' + phone_number);

  custom_post(url, data, function() {
    alert('SMS invite sent!');
    location.reload(true);
  });
};

// Custom method for accepting a pending user
window.accept_pending_user = function(base_url, user_id, token) {
  const url = base_url + '/v1/admin/user/accept';
  const data = {
    user_id: user_id,
    token: token,
  };

  console.log('Accepting user ' + user_id);

  custom_post(url, data, function() {
    alert('User accepted!');
    location.reload(true);
  });
};

// Custom method for creating a talent account
window.create_prefilled_talent = function(base_url, token) {
  const firstname = document.getElementById('firstname').value;
  const lastname = document.getElementById('lastname').value;
  const user_type = document.getElementById('usertype').value;

  if (firstname == '' || lastname == '') {
    alert('Please fill all fields!');
    return;
  }

  const url = base_url + '/v1/admin/user/prefill/create';
  const data = {
    firstname: firstname,
    lastname: lastname,
    user_type: user_type,
    token: token,
  };

  console.log(
    'Creating ' + user_type + ' account for user ' + firstname + ' ' + lastname
  );

  custom_post(url, data, function(result) {
    alert('Account created!');
    const user_id = result['user_id'];
    window.location.href = `/login?as_user=PROFILE_${user_id}`;
  });
};

// Custom method for creating a client account
window.create_prefilled_client = function(base_url, token) {
  const firstname = document.getElementById('firstname').value;
  const lastname = document.getElementById('lastname').value;
  const company_name = document.getElementById('company_name').value;
  const vat = document.getElementById('vat').value;

  if (vat == '' || company_name == '' || firstname == '' || lastname == '') {
    alert('Please fill all fields!');
    return;
  }

  const url = base_url + '/v1/admin/user/prefill/create';
  const data = {
    firstname: firstname,
    lastname: lastname,
    user_type: 'CLIENT',
    name: company_name,
    vat: vat,
    token: token,
  };

  console.log('Creating CLIENT account for user ' + firstname + ' ' + lastname);

  custom_post(url, data, function(result) {
    alert('Account created!');
    const user_id = result['user_id'];
    window.location.href = `/login?as_user=PROFILE_${user_id}`;
  });
};
