import React, { Component } from 'react';
import { object, bool, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'clsx';

import { createUrl } from 'utils/urlHelper';

import p from 'polyglot/polyglot';

import {
  showSignupModal as showSignupModalAction,
  showSignupModalClient as showSignupModalClientAction,
} from 'actions/signup';

import Button from 'components/Button/Button';
import heroImage from '../../../../media/jpg/hero-image.jpg';

import styles from './hero.scss';

export class Hero extends Component {
  /**
   * Declare expected prop types.
   *
   * @type {object}
   */
  static propTypes = {
    history: object.isRequired,
    auth: shape({
      isAuthenticated: bool.isRequired,
    }).isRequired,
    showSignupModal: func.isRequired,
    showSignupModalClient: func.isRequired,
  };

  onClickSignup = e => {
    e.preventDefault();

    const {
      auth: { isAuthenticated },
      history,
      showSignupModal,
    } = this.props;

    if (isAuthenticated) {
      return history.push(createUrl('signup'));
    }

    return showSignupModal();
  };

  /**
   * Render Hero.
   *
   * @return {Object}
   */

  render() {
    const { showSignupModalClient } = this.props;

    return (
      <section className={styles.wrapper}>
        <div className={styles.hero}>
          <div className={styles.imageBlock}>
            <img
              className={styles.heroImage}
              src={heroImage}
              alt="Hero Image"
            ></img>
          </div>
          <article className={styles.textBlock}>
            <h2>
              <span className={styles.row}>The best of the best,</span>
              <span className={styles.row}>all under one roof.</span>
            </h2>
            <p>{p.t('hero.ingress')}</p>
            <div className={styles.buttonsContainer}>
              <Button
                className={classNames(styles.button)}
                onClick={showSignupModalClient}
              >
                JOIN AS CLIENT
              </Button>
              <Button
                white
                ghost
                className={classNames(styles.button, styles.bottomButton)}
                onClick={this.onClickSignup}
              >
                JOIN AS TALENT
              </Button>
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default withRouter(
  connect(({ auth }) => ({ auth }), {
    showSignupModal: showSignupModalAction,
    showSignupModalClient: showSignupModalClientAction,
  })(Hero)
);
