import { useEffect } from 'react';
import { node, func } from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';

const fileTarget = {
  drop({ onDrop }, monitor) {
    onDrop(monitor.getItem().files);
  },
};

const FileDropZone = ({ connectDropTarget, isOver, onHover, children }) => {
  useEffect(() => {
    onHover(isOver);
  }, [isOver]);

  return connectDropTarget(children);
};

FileDropZone.propTypes = {
  children: node.isRequired,
  onDrop: func,
  onHover: func,
};

FileDropZone.defaultProps = {
  onDrop: () => {},
  onHover: () => {},
};

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(FileDropZone);
