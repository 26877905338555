import React from 'react';
import { string, oneOf } from 'prop-types';
import classNames from 'clsx';

import styles from './avatar.scss';

const stringToColor = arr => {
  let hash = 0;

  arr.forEach(character => {
    hash += character.toLowerCase().charCodeAt();
  });

  switch (hash % 5) {
    case 1:
      return styles.orange;
    case 2:
      return styles.yellow;
    case 3:
      return styles.green;
    case 4:
      return styles.blue;
    case 0:
    default:
      return styles.red;
  }
};

const Avatar = ({ src, children, className: customClassName, variant }) => {
  const words = children.split(' ');
  const [first = '', second = ''] = words.map(word => word.substring(0, 1));

  const bgColor = stringToColor([first, second]);

  const className = classNames(styles.wrapper, bgColor, customClassName, {
    [styles.circle]: variant === 'circle',
    [styles.rounded]: variant === 'rounded',
    [styles.square]: variant === 'square',
  });

  if (src) {
    return (
      <img className={classNames(className, styles.transparent)} src={src} />
    );
  }

  return (
    <div className={className}>
      {first}
      {second}
    </div>
  );
};

Avatar.propTypes = {
  src: string,
  children: string,
  className: string,
  variant: oneOf(['circle', 'rounded', 'square']),
};

Avatar.defaultProps = {
  src: '',
  children: '',
  className: null,
  variant: 'circle',
};

export default Avatar;
