import React from 'react';
import { object, func, array, bool, string } from 'prop-types';
import classNames from 'clsx';
import p from 'polyglot/polyglot';

import Button from 'components/Button/Button';
import SearchField from '../SearchField/SearchField';
import Selector from '../Selector/Selector';

import styles from './desktop-filter.scss';

const DesktopFilter = ({
  query,
  firstRender,
  form,
  selectedSection,
  submitHandler,
  onChangeSearchField,
  onClickClear,
  toggleSection,
  onChange,
  loading,
  sections,
  hideFilter,
}) => {
  const { selectors = [] } =
    sections.find(({ tag }) => tag === selectedSection) || {};

  return (
    <form
      className={classNames(styles.wrapper, {
        [styles.loading]: loading,
      })}
      onSubmit={submitHandler}
      noValidate
      autoComplete="off"
    >
      <div className={styles.menu}>
        <div className={styles.category}>
          {sections.map(({ label, tag, is_active }) => (
            <div className={styles.block} key={tag}>
              <button
                type="button"
                className={classNames(styles.blockHeader, {
                  [styles.active]: is_active && !firstRender,
                })}
                onClick={e => toggleSection(e, tag)}
              >
                {label}
              </button>
            </div>
          ))}
        </div>
        <SearchField value={query} onChange={onChangeSearchField} />
      </div>
      <div
        className={classNames(styles.selectors, {
          [styles.visible]: selectors.length,
        })}
      >
        {selectors.map(selector => (
          <Selector
            key={selector.name}
            formInput={form?.[selector.name]}
            onChange={onChange}
            {...selector}
          />
        ))}
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContainer}>
            <div>
              <Button dark small disabled={loading} onClick={submitHandler}>
                {p.t('talents.form.submit')}
              </Button>
              <Button dark ghost small onClick={onClickClear}>
                {p.t('talents.form.clear')}
              </Button>
            </div>
            <Button dark ghost small onClick={hideFilter}>
              Hide
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

DesktopFilter.propTypes = {
  query: string,
  firstRender: bool,
  form: object,
  selectedSection: string,
  submitHandler: func,
  onChangeSearchField: func,
  onClickClear: func,
  toggleSection: func,
  onChange: func,
  loading: bool,
  sections: array.isRequired,
  hideFilter: func,
};

DesktopFilter.defaultProps = {
  form: {},
  query: '',
  firstRender: true,
  selectedSection: '',
  submitHandler: () => {},
  onChangeSearchField: () => {},
  onClickClear: () => {},
  toggleSection: () => {},
  onChange: () => {},
  loading: false,
  hideFilter: () => {},
};

export default React.memo(DesktopFilter);
