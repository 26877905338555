import React from 'react';
import { string } from 'prop-types';

import styles from './information-box.scss';

const InformationBox = ({ title, text }) => {
  return (
    <div className={styles.box}>
      <p className={styles.title}>{title}</p>
      <span className={styles.text}>{text}</span>
    </div>
  );
};

InformationBox.propTypes = {
  title: string,
  text: string,
};

InformationBox.defaultProps = {
  title: '',
  text: '',
};

export default InformationBox;
