import React, { Component } from 'react';
import { node } from 'prop-types';
import Swiper from 'swiper';
import ArrowIcon from 'gfx/vector/arrow-icon.svg';

import styles from './image-slider.scss';

export default class ImageSlider extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  constructor(props) {
    super(props);

    this.swiper = null;
  }

  componentDidMount() {
    this.swiper = new Swiper(this.imageSlider, {
      containerModifierClass: styles.container,
      wrapperClass: styles.slides,
      slideClass: styles.slide,
      loop: true,
      navigation: {
        nextEl: this.nextButton,
        prevEl: this.prevButton,
      },
    });
  }

  componentWillUnmount() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className={styles.wrapper}>
        <div
          className={styles.container}
          ref={ref => {
            this.imageSlider = ref;
          }}
        >
          <div className={styles.slides}>
            {children.map((child, i) => (
              <div key={`slide-${i}`} className={styles.slide}>
                {child}
              </div>
            ))}
          </div>
        </div>

        <button
          data-arrow-type="left"
          className={styles.prevButton}
          ref={ref => {
            this.prevButton = ref;
          }}
        >
          <ArrowIcon />
        </button>
        <button
          data-arrow-type="right"
          className={styles.nextButton}
          ref={ref => {
            this.nextButton = ref;
          }}
        >
          <ArrowIcon />
        </button>
      </div>
    );
  }
}
