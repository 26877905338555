import React, { Component } from 'react';

import styles from './talent.scss';
import TalentModelsImageOne from '../../../media/jpg/talent-models-image-one.jpg';
import TalentModelsImageTwo from '../../../media/jpg/talent-models-image-two.jpg';
import MetaTags from 'components/MetaTags/MetaTags';

/**
 * This is the Talent component.
 *
 * @author Pelle Lundgren <pelle.lundgren@vntrs.com>
 */
export class Talent extends Component {
  /**
   * Render Counter.
   *
   * @return {Object}
   */
  render() {
    return (
      <section className={styles.wrapper}>
        <MetaTags
          title="Jobba som modell i Stockholm - Ansök online | KSTING"
          description="Hos KSTING kan du ansöka om att vara del av vårt nätverk av
          modeller."
        />
        <div className={styles.header}>
          <h1 className={styles.title}>
            Jobba som modell. Ansök till KSTING idag.
          </h1>
          <p className={styles.info}>
            Ansök om att bli modell och bli därmed en del av vårt växande
            nätverk.
          </p>
        </div>
        <div className={styles.block}>
          <div className={styles.article}>
            <p className={styles.copy}>
              Har du fått höra att du är för lång eller för kort? För liten
              eller för stor? Eller har du jobbat som modell länge, men är trött
              på den hierarkiskt uppbyggda och oföränderliga branschen? Oavsett
              vad, så är du välkommen att skicka in en ansökan till oss. Vi är
              ständigt på jakt efter nya & coola looks!{' '}
            </p>
            <blockquote className={styles.quote}>
              Vår vision är att utmana de befintliga strukturerna och ge
              tillbaka makten till de som förtjänar det.
            </blockquote>
          </div>
          <div className={styles.frame}>
            <img
              className={styles.image}
              src={TalentModelsImageOne}
              alt="Section Image"
            ></img>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.frame}>
            <img
              className={styles.image}
              src={TalentModelsImageTwo}
              alt="Section Image"
            ></img>
          </div>
          <div className={styles.article}>
            <p className={styles.copy}>
              Genom att bli en del av KSTING erbjuder vi en plattform där det är
              du som bestämmer - vad gäller allt från utformning av portfolio
              till vilka jobb du väljer att ta dig an. Detta genererar i ett
              friare arbetssätt med lägre avgifter samtidigt som vi finns
              tillgängliga dygnet runt för rådgivning.
            </p>
            <blockquote className={styles.quote}>
              Vi söker modeller i alla åldrar, storlekar, etniciteter och
              personligheter.
            </blockquote>
            <p className={styles.copy}>
              Ansök idag och låt oss tillsammans göra modebranschen till en
              plats fylld av kreativitet och entreprenörsanda.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Talent;
