import React, { useEffect, useState, useRef } from 'react';
import { node, string, oneOf } from 'prop-types';
import classNames from 'clsx';

import styles from './tool-tip.scss';

const ToolTip = ({ children, title, placement }) => {
  const toolTipRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [side, position] = placement.split('-');

  const handleClick = event => {
    if (!toolTipRef.current.contains(event.target)) {
      setOpen(false);
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen, toolTipRef]);

  return (
    <div
      ref={toolTipRef}
      className={styles.wrapper}
      onClick={() => {
        if (!isOpen) {
          setOpen(true);
        }
      }}
    >
      {children}
      {isOpen && (
        <div
          className={classNames(styles.container, {
            [styles.top]: side === 'top',
            [styles.bottom]: side === 'bottom',
            [styles.left]: side === 'left',
            [styles.right]: side === 'right',
            [styles.start]: position === 'start',
            [styles.end]: position === 'end',
            [styles.middle]: side && !position,
          })}
        >
          <div className={styles.bubble}>
            <span>{title}</span>
            <div
              className={classNames(styles.tail, {
                [styles.top_tail]: side === 'top',
                [styles.bottom_tail]: side === 'bottom',
                [styles.left_tail]: side === 'left',
                [styles.right_tail]: side === 'right',
                [styles.start_tail]: position === 'start',
                [styles.end_tail]: position === 'end',
                [styles.middle_tail]: side && !position,
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ToolTip.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  placement: oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-start',
    'left',
    'left-end',
  ]),
};

ToolTip.defaultProps = {
  placement: 'bottom',
};

export default ToolTip;
