const canvas = document.createElement('canvas');
const canUseWebP =
  canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0;

// Helper to get webP image instead. We just change the file ext.
export const getWebPImage = file => {
  if (file && canUseWebP) {
    return file.substr(0, file.lastIndexOf('.')) + '.webp';
  }

  return file;
};
