import React, { Component } from 'react';

import styles from './agency.scss';
import AgencyModelsImageOne from '../../../media/jpg/agency-models-image-one.jpg';
import AgencyModelsImageTwo from '../../../media/jpg/agency-models-image-two.jpg';
import MetaTags from 'components/MetaTags/MetaTags';

/**
 * This is the Agency component.
 *
 * @author Pelle Lundgren <pelle.lundgren@vntrs.com>
 */
export class Agency extends Component {
  /**
   * Render Counter.
   *
   * @return {Object}
   */
  render() {
    return (
      <section className={styles.wrapper}>
        <MetaTags
          title="Modellagentur i Stockholm - Se våra modeller | KSTING"
          description="Hos KSTING kan du bläddra bland hundratals professionella
modeller. Förhandla, anlita och samordna utan krångel."
        />
        <div className={styles.header}>
          <h1 className={styles.title}>KSTING - Våra modeller</h1>
          <p className={styles.info}>
            Hos KSTING kan du bläddra bland hundratals professionella modeller.
          </p>
        </div>
        <div className={styles.block}>
          <div className={styles.article}>
            <p className={styles.copy}>
              Vi erbjuder en digital lösning med en mängd användarvänliga
              verktyg för att upptäcka, förhandla och boka modeller.{' '}
            </p>
            <blockquote className={styles.quote}>
              Med en process som är både tids- och kostnadseffektiv, ökar vi
              modellernas inkomster, sänker kundernas kostnader och bygger
              därmed värde för alla inblandade parter.
            </blockquote>
          </div>
          <div className={styles.frame}>
            <img
              className={styles.image}
              src={AgencyModelsImageOne}
              alt="Section Image"
            ></img>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.frame}>
            <img
              className={styles.image}
              src={AgencyModelsImageTwo}
              alt="Section Image"
            ></img>
          </div>
          <div className={styles.article}>
            <p className={styles.copy}>
              Visionen med KSTING är att förändra den traditionella
              modebranschen och skapa bättre arbetsvillkor för våra modeller.
              Genom att signa up på KSTING är du därmed med och bidrar till att
              göra modebranschen till en schysstare plats för alla. Du hittar
              oss i centrala Stockholm, där majoriteten av våra modeller är
              bosatta. Vi representerar även modeller runt om i hela Europa.
            </p>
            <blockquote className={styles.quote}>
              Signa up för att ta del av hundratals professionella modeller för
              ditt kommande jobb.
            </blockquote>
            <p className={styles.copy}>
              Kort sagt samlar vi modeller, kreatörer och kunder under ett och
              samma tak.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Agency;
