import React, { useImperativeHandle, useRef } from 'react';
import { node, bool, func } from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'clsx';

import styles from './drag-drop-card.scss';

const ItemTypes = {
  CARD: 'card',
};

const Card = React.forwardRef(
  ({ children, isDragging, connectDragSource, connectDropTarget }, ref) => {
    const elementRef = useRef(null);

    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <div
        ref={elementRef}
        className={classNames(styles.wrapper, {
          [styles.dragging]: isDragging,
        })}
      >
        {children && children}
      </div>
    );
  }
);

/**
 * Declare expected property types.
 */
Card.propTypes = {
  children: node,
  isDragging: bool,
  connectDragSource: func.isRequired,
  connectDropTarget: func.isRequired,
  onMove: func, // eslint-disable-line
  onDrop: func, // eslint-disable-line
};

/**
 * Set default properties.
 */
Card.defaultProps = {
  children: null,
  isDragging: false,
  onMove: () => {},
  onDrop: () => {},
};

export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }

      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical and horizontal middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        (dragIndex < hoverIndex && hoverClientX < hoverMiddleX)
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        (dragIndex > hoverIndex && hoverClientX > hoverMiddleX)
      ) {
        return;
      }

      // Time to actually perform the action
      props.onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: props => ({
        id: props.id,
        index: props.index,
      }),
      endDrag({ onDrop }, monitor) {
        const { id: droppedId, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if (didDrop) {
          onDrop(droppedId, originalIndex);
        }
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Card)
);
