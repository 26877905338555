import React from 'react';
import { object, func, array, bool, string } from 'prop-types';
import classNames from 'clsx';
import p from 'polyglot/polyglot';

import Button from 'components/Button/Button';
import BlackTieIcon from 'components/BlackTieIcon/BlackTieIcon';
import SearchField from '../SearchField/SearchField';
import Selector from '../Selector/Selector';

import styles from './mobile-filter.scss';

const MobileFilter = ({
  query,
  firstRender,
  form,
  submitHandler,
  onChangeSearchField,
  onClickClear,
  toggleSection,
  onChange,
  loading,
  sections,
}) => {
  return (
    <form
      className={classNames(styles.wrapper, {
        [styles.loading]: loading,
      })}
      onSubmit={submitHandler}
      noValidate
      autoComplete="off"
    >
      <SearchField value={query} onChange={onChangeSearchField} />

      <div className={styles.header}>
        <p className={styles.title}>{p.t('talents.form.title')}</p>

        <button type="button" className={styles.clear} onClick={onClickClear}>
          {p.t('talents.form.clear')}
        </button>
      </div>

      <div className={styles.menu}>
        {sections.map(({ label, tag, is_active, selectors }) => (
          <div className={styles.block} key={tag}>
            <button
              type="button"
              className={classNames(styles.blockHeader, {
                [styles.active]: is_active && !firstRender,
              })}
              onClick={e => toggleSection(e, tag)}
            >
              {label}

              <BlackTieIcon
                className={styles.icon}
                icon="angle-down"
                weight="btb"
              />
            </button>
            <div
              className={classNames(styles.selectors, {
                [styles.visible]: is_active && !firstRender,
              })}
            >
              <div className={styles.selectorsInner}>
                {selectors.map(selector => (
                  <Selector
                    key={selector.name}
                    formInput={form[selector.name]}
                    onChange={onChange}
                    {...selector}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Button small wide disabled={loading} onClick={submitHandler}>
        {p.t('talents.form.submit')}
      </Button>
    </form>
  );
};

MobileFilter.propTypes = {
  query: string,
  firstRender: bool,
  form: object,
  submitHandler: func,
  onChangeSearchField: func,
  onClickClear: func,
  toggleSection: func,
  onChange: func,
  loading: bool,
  sections: array.isRequired,
};

MobileFilter.defaultProps = {
  form: {},
  query: '',
  firstRender: true,
  submitHandler: () => {},
  onChangeSearchField: () => {},
  onClickClear: () => {},
  toggleSection: () => {},
  onChange: () => {},
  loading: false,
};

export default React.memo(MobileFilter);
